var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d631c7148c44202413ddee57c901a8fa395fe956"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { getSentryConfig, beforeSendDefault } from '@patrianna/shared-utils/getSentryConfig'

const ignoreErrorCodes = ['err_frozen', 'err_auth']

Sentry.init(
  getSentryConfig({
    // <denyUrls> can be extended here
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control

    beforeSend(event, hint) {
      // Extra logic can be added here
      return beforeSendDefault(event, hint)
    },

    // <ignoreErrors> can be extended here
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  })
)
